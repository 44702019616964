<template>
  <div class="network_page">
    <div class="row phones">
      <div class="img1">
          <div class="img1all text-center text-white">
              <h1>{{ this.$route.params.id }} deals</h1>
              <p class="pimg">Compare the best {{ this.$route.params.id }} deals</p>
          </div>
      </div>
    </div>
    <div class="container mt-5">
        <div class="row">
          <div class="col-md-3">
              <div class="row bg_sky m-auto pb-5">
                  <div class="network col-lg-12 box_shadow pt-4">
                      <h4 class="pb-1 pt-3 pb-2">Providers</h4>
                      <div class="row justify-content-center">
                          <template v-for="(network, i) in networks" >
                              <router-link :key="network.title + i" :to=" '/networks/' + network.slug + '?network=' + network.id " tag="a" 
                                class="network-sub-product brand_phone brand_product">
                                  <img @click="filnetworkAdd(network.id)" :src="publicPath + network.img " alt>
                              </router-link>
                          </template>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-9 order-first">
              <div class="row  m-0"
                v-show="
                  (this.$route.query.reseller != null) |
                    (this.$route.query.data != null) |
                    (this.$route.query.text != null) |
                    (this.$route.query.minutes != null) |
                    (this.$route.query.simNetwork != null) |
                    (this.$route.query.network != null) |
                    (this.$route.query.product != null)
                "
              >
                <template v-for="(filter, i) in filters.data">
                  <div :key="filter.title + i" class="card mobile_list border border-secondary mb-3 col-lg-12 col-md-5 col-xs-12 col-sm-12">
                      <div class="row  d-flex align-items-center">
                        <div class="col-">
                          <div class="row">
                            <div class="img_free col-lg-2 text-center m-auto">
                              <img style="width: 4rem; display: block;margin: auto;margin-top: .33rem;margin-bottom: .33rem;" :src="publicPath + filter.img" alt="">
                              <img style="width: 4rem; display: block;margin: auto;" 
                              v-if="filter.network.img && filter.network.img != undefined" :src="publicPath + filter.network.img" alt="">
                              
                            </div>
                            <div class="col-md-8 card-body ">
                              <div class="row">
                                <div class="col-md-12">
                                    <h5 class="card-title">{{ filter.title }}</h5>
                                    <p>{{filter.contract}} month contract</p>
                                </div>
                                <div class="col-lg-12 pb-0 pl-0">
                                    <ul class="card_info row d-flex align-items-center justify-content-center">
                                      <li v-if="filter.data" class="col-2 border-right">
                                            <p v-if="filter.data === '123456789'"><strong style="font-size: 20px;">Unltd</strong><br> Data</p>
                                            <p  v-else-if="filter.data.length >= 4 && filter.data >= 1000">
                                              <strong style="font-size: 20px;">{{ filter.data / 1000 }}GB</strong><br> Data
                                            </p>
                                            <p v-else><strong style="font-size: 20px;">{{ filter.data }}MB</strong><br> Data</p>
                                        </li>
                                        <li v-if="filter.minutes" class="col-2 border-right">
                                            <p v-if="filter.minutes === '987654321' || filter.minutes === '123456789'">
                                              <strong style="font-size: 20px;">Unltd</strong><br>minutes
                                            </p>
                                            <p v-else>
                                              <strong style="font-size: 20px;">{{filter.minutes}}</strong><br>minutes
                                            </p>
                                        </li>
                                        <li v-if="filter.text" class="col-2 border-right">
                                            <p v-if="filter.text === '987654321' || filter.text === '123456789'">
                                              <strong style="font-size: 20px;">Unltd</strong><br>Texts
                                            </p>
                                            <p v-else>
                                              <strong style="font-size: 20px;">{{filter.text}}</strong><br>Texts
                                            </p>
                                        </li>
                                        
                                        <li class="col-2 border-right"><p><strong style="font-size: 20px;">{{filter.contract}}</strong> month contract</p></li>
                                        <li class="col-2">
                                            <p v-if="filter.upfront_cost === '1'"><strong style="font-size: 20px;">£0</strong><br> upfront</p>
                                            <p v-else><strong style="font-size: 20px;">£{{ filter.upfront_cost }}</strong><br> upfront</p>
                                        </li>
                                    </ul>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-2 mt-2 text-center">
                              <div class="box-label cust_label">
                                <div class="label-product label_new" v-if="filter.pormoted == 1" >
                                  <span>Pormoted</span>
                                </div>
                              </div>
                                
                                <p><strong style="font-size: 20px;">£{{ filter.monthly_cost }}</strong><br>Per Month</p>
                                <a :href="filter.link" class="btn btn-info  see_deal">Buy Now</a>
                                <img style="width: 4.5rem;margin-bottom: 0.5rem;" v-if="filter.reseller.img" :src="publicPath + filter.reseller.img">
                                <img style="width: 4.5rem;margin-bottom: 0.5rem;" v-else :src="publicPath + filter.network.img">
                                <!-- <a @click=" compareF(filter.slug), (isDisable = filter.id) "
                                  class="compare btn btn-outline-info mt-2"
                                  :class="{ disable: isDisable === filter.id } " title="Compare" >
                                  <i class="lnr lnr-sync"></i>
                                  Compare
                                </a> -->
                            </div>
                          </div>
                        </div>
                          

                          
                      </div>
                  </div>
                </template>
                <template class="col-12" v-if="filters.data < 1">
                  <div class="grid-no-results">
                    <p class="grid-no-results__message">
                      no deals found in your depend on your filtration
                    </p>
                  </div>
                </template>
                <div class="col-12 mt-5">
                  <nav aria-label="Page navigation example" class="row justify-content-center">
                    <pagination :limit="2" :align="'center'" :data="filters" @pagination-change-page="getFilter" >
                      <span slot="prev-nav">&lt; Previous</span>
                      <span slot="next-nav">Next &gt;</span>
                    </pagination>
                  </nav>
                </div>
              </div>
            </div>
        </div>
        
    </div>
  </div>
</template>
<script>
export default {
  name: "bodyComp",
  components: {},
  data() {
    return {
      active_el: 0,
      offers: {},
      networks: [],
      filters: {},
      publicPath: "https://app.contractsexpert.co.uk/storage/app/public/"
    };
  },
  mounted: function() {
    this.getFilter();
    this.getNetwork();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    },
    getquery() {
      return this.$route.query;
    },
    returnProduct() {
      return this.$route.params.product;
    },
    networkPramter() {
      return this.$route.params.id;
    },
    catPramter() {
      return this.$route.query.cat_slug;
    },
    brandPramter() {
      return this.$route.query.brand_slug;
    }
  },
  watch: {
    getFullPath() {
      this.getOffers();
      this.getFilter();
    },
    query() {
      this.getOffers();
      this.getFilter();
    }
  },
  methods: {
    activate: function(el) {  
      this.active_el = el;
    },
    getOffers(page = 1) {
      this.loading = true;
      this.$http
        .get("offers?count=9&page=" + page)
        .then(response => {
          this.offers = response.data;
          response => (this.offers = response.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getNetwork() {
      this.loading = true;
      this.$urlmain
        .get("network-popular.json")
        .then(response => {
          this.networks = response.data.data;
          response => (this.networks = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getFilter(page = 1) {
      this.loading = true;
      this.$http
        .get(
          "filter?count=9&reseller" +
            [
              this.$route.query.reseller == "undefined"
                ? " "
                : this.$route.query.reseller
            ] +
            "&data=" +
            [
              this.$route.query.data == "undefined"
                ? " "
                : this.$route.query.data
            ] +
            "&product=" +
            [
              this.$route.query.product == "undefined"
                ? " "
                : this.$route.query.product
            ] +
            "&text=" +
            [
              this.$route.query.text == "undefined"
                ? " "
                : this.$route.query.text
            ] +
            "&minutes=" +
            [
              this.$route.query.minutes == "undefined"
                ? " "
                : this.$route.query.minutes
            ] +
            "&simNetwork=" +
            [
              this.$route.query.simNetwork == "undefined"
                ? " "
                : this.$route.query.simNetwork
            ] +
            "&network=" +
            [
              this.$route.query.network == "undefined"
                ? " "
                : this.$route.query.network
            ] +
            "&color=" +
            [
              this.$route.query.color == "undefined"
                ? " "
                : this.$route.query.color
            ] +
            "&storage=" +
            [
              this.$route.query.storage == "undefined"
                ? " "
                : this.$route.query.storage
            ] +
            "&page=" +
            page
        )
        .then(response => {
          this.filters = response.data;
          response => (this.filters = response.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
