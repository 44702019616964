<template v-if="singleOffer.content == 1">
  <section id="contents">
    <div class="description description_sec pt-90 mt-5 pt-5 description_section">
      <div class="container">
        <div class="row m-0">
          <!-- <div class="col-lg-3">
            <div id="list-desc" class="list-group sticky-top">
               <h2 class=" m-0 pb-4 pl-4">
                  Description
                </h2>
              <a v-for="(content, i) in this.singleOffer.contents" :key="i" 
                class="list-group-item list-group-item-action" :href="'#tab-' + content.title">{{ content.title }}</a>
            </div>
          </div> -->
          <div class="col-lg-8 desc_body">
            <div
              v-for="(content, i) in this.singleOffer.contents"
              :key="i"
              :id="'tab-' + content.title"
              class="mt-20 pb-20"
            >
              <h3 class="text-left mb-40 pt-4">{{ content.title }}</h3>
              <div v-html="content.desc"></div>
              <div class="mt-20" style="border:solid 2px gray"></div>
            </div>
          </div>
          <div class="col-lg-4 Networks_Brands_img">
            <div class="row bg_sky m-auto pb-5">
                  <div class="network col-lg-12 box_shadow pt-4">
                      <h4 class="pb-1 pt-3 pb-2">Our Top Providers</h4>
                      <div class="row justify-content-center">
                          <template v-for="(network, i) in networks" >
                              <router-link :key="network.title + i" :to=" '/networks/' + network.slug + '?network=' + network.id " tag="a" 
                                class="network-sub-product brand_phone brand_product">
                                  <img @click="filnetworkAdd(network.id)" :src="publicPath + network.img " alt>
                              </router-link>
                          </template>
                      </div>
                  </div>
              </div>
             
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "descriptionCopm",
  data() {
    return { singleOffer: [], networks: [], popularCat: [], publicPath: "https://app.contractsexpert.co.uk/storage/app/public/"
 };
  },
  mounted: function() {
    this.getSingleOffer();
    this.getPopCat();
    this.getNetwork();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.getSingleOffer();
      this.getPopCat();
    }
  },
  methods: {
    getSingleOffer() {
      this.loading = true;
      this.$http
        .get("network/" + this.$route.params.id)
        .then(response => {
          this.singleOffer = response.data.data;
          response => (this.singleOffer = response.data.data);
          // console.log("sdf dfgdfg dfg " + this.singleOffer)
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getPopCat() {
      this.loading = true;
      this.$urlmain
        .get("category-popular.json")
        .then(response => {
          this.popularCat = response.data.data;
          response => (this.popularCat = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getNetwork() {
      this.loading = true;
      this.$urlmain
        .get("network-popular.json")
        .then(response => {
          this.networks = response.data.data;
          response => (this.networks = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
