<template>
  <div class="internal-product">
    <PageLoader />
    
    <allProduct />
    <descriptionCopm />
  </div>
</template>

<script>
import allProduct from "@/views/pages/networks/component/body.vue";
import descriptionCopm from "@/views/pages/networks/component/description.vue";
import PageLoader from "@/components/units/PageLoader.vue";
export default {
  name: "singleProduct",
  components: {
    allProduct,
    descriptionCopm,
    PageLoader
  },
  data() {
    return {
      singleOffer: [],
      singColor: []
    };
  },
  mounted() {
   window.scrollTo(0, 0)
    this.getSingleOffer();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
    // getQuery() {
    //   return this.$route.query;
    // }
  },
  watch: {
    getFullPath() {
      this.getSingleOffer();
    }
  },
  methods: {
    getSingleOffer() {
      this.isLoading = true;
      this.$http
        .get("offers/" + this.$route.params.id)
        .then(response => {
          this.singleOffer = response.data;
          response => (this.singleOffer = response.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.thumb-example {
  height: 480px;
  background-color: white;
}

.swiper {
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  &.gallery-top {
    height: 100%;
    width: 100%;
  }
  &.gallery-thumbs {
    height: 33%;
    box-sizing: border-box;
    padding: 0;
  }
  &.gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  &.gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
  .swiper-slide {
    margin-top: 0.5em;
  }
}
</style>
